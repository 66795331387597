import React from 'react';

import th from 'ui/theme';

const Instagram = ({
  fill = th.colors.black,
  ...rest
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={32} height={31} viewBox="0 0 32 31" {...rest}>
    <g fill={fill} fillRule="nonzero">
      <path d="M30.936 9.112c-.072-1.647-.339-2.779-.72-3.76a7.563 7.563 0 00-1.792-2.748A7.628 7.628 0 0025.68.817c-.987-.381-2.113-.647-3.76-.72C20.261.018 19.735 0 15.527 0S10.792.018 9.14.09C7.493.164 6.36.43 5.38.812a7.562 7.562 0 00-2.75 1.793A7.63 7.63 0 00.846 5.346c-.382.987-.648 2.113-.72 3.76-.08 1.66-.098 2.186-.098 6.394s.018 4.735.091 6.388c.073 1.647.34 2.779.72 3.76a7.643 7.643 0 001.793 2.748 7.628 7.628 0 002.743 1.787c.986.381 2.113.647 3.76.72 1.652.073 2.18.09 6.387.09 4.208 0 4.735-.017 6.388-.09 1.647-.073 2.779-.339 3.76-.72a7.928 7.928 0 004.535-4.535c.381-.987.648-2.113.72-3.76.073-1.653.091-2.18.091-6.388s-.006-4.735-.079-6.388zm-2.79 12.655c-.067 1.513-.322 2.33-.534 2.876a5.141 5.141 0 01-2.942 2.942c-.545.212-1.368.466-2.876.533-1.635.073-2.125.09-6.26.09-4.136 0-4.632-.017-6.261-.09-1.514-.067-2.331-.32-2.876-.533a4.77 4.77 0 01-1.78-1.156 4.819 4.819 0 01-1.157-1.78c-.212-.545-.466-1.369-.532-2.876-.073-1.635-.091-2.126-.091-6.26 0-4.136.018-4.633.09-6.261.067-1.514.321-2.331.533-2.876a4.71 4.71 0 011.163-1.78 4.811 4.811 0 011.78-1.157c.545-.212 1.368-.466 2.876-.533 1.635-.072 2.125-.09 6.26-.09 4.142 0 4.632.018 6.26.09 1.515.067 2.332.321 2.877.533a4.766 4.766 0 011.78 1.156 4.818 4.818 0 011.156 1.78c.212.546.467 1.369.533 2.877.073 1.634.091 2.125.091 6.26s-.018 4.62-.09 6.255z" />
      <path d="M15.527 7.538A7.964 7.964 0 007.565 15.5a7.964 7.964 0 007.962 7.962A7.964 7.964 0 0023.49 15.5a7.964 7.964 0 00-7.962-7.962zm0 13.127a5.166 5.166 0 11.002-10.331 5.166 5.166 0 01-.002 10.33zM25.663 7.223a1.859 1.859 0 11-3.718 0 1.859 1.859 0 013.718 0z" />
    </g>
  </svg>
);

export default Instagram;
